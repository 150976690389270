export function inputErrorCheck(formEl) {
  $(formEl).each(function () {
    if ($(this).find($('span.error')).length == 1) {
      $(this).addClass('p-contact__input-wrap--caution');
      $(this).children('span.error').addClass('p-contact__error-msg');
    }
  });
}

export function inputAutofill() {
  $('#zip-button').on('click', function () {
    AjaxZip3.zip2addr('zip-code', '', 'address', 'address');
  });
}
