export function hoverMenu() {
  $(".dropdown-menu").hover(
    function () {
      $(this).children('.l-header-pc__nav-contents-wrap').addClass('open');
      $(this).children('.l-header-pc__nav-arrow').addClass('active');

    },
    function () {
      $(this).children('.l-header-pc__nav-contents-wrap').removeClass('open');
      $(this).children('.l-header-pc__nav-arrow').removeClass('active');
    }
  );
}
