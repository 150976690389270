export function modal() {
  $('.js-modalOpen').on('click', function () {
    $('.js-modalContents').addClass('active');
    console.log("click");
    return false;
  });

  $('.js-modalClose, .p-model-modal__button').on('click', function () {
    $('.js-modalContents').removeClass('active');
  });

  $(document).on('click', function (e) {
    if (!$(e.target).closest('.p-model-modal__body').length) {
      $('.js-modalContents').removeClass('active');
    }
  });

  $(window).on('load', function () {
    $('.js-modalContents').addClass('active');
  });
}
