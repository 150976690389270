import {
  scroll,
  scrollFadeIn,
  setFadeIn,
  smoothScroll
} from "./components/scroll";
import {
  hamburgerMenuButton
} from "./components/hamburgerMenuButton";
import {
  slider
} from "./components/slider";
import {
  accordion
} from "./components/accordion";
import {
  hoverMenu
} from "./components/hoverMenu";
import {
  tab
} from "./components/tab";
import {
  inputErrorCheck,
  inputAutofill
} from "./components/inputError";
// import { pageTop } from "./components/pageTop";
import {
  modal
} from "./components/modal";



$(document).ready(function () {

  (() => {
    /*
    アニメーション用関数
    */
    //スクロール時に付与されるクラス定義
    scroll();
    smoothScroll();
    setFadeIn();
  })();

  (() => {
    /*
    ハンバーガーメニュー用関数
    */
    //ハンバーガーメニューボタン定義
    const buttonEl = "#hamburgerButton";
    //ハンバーガーメニュー定義
    const menuEl = "#hamburgerMenu";
    hamburgerMenuButton(buttonEl, menuEl);
  })();

  (() => {
    /*
    スライダー用関数
    */
    slider();
  })();

  (() => {
    /*
    アコーディオン用関数
    */
    //アコーディオンラッパー定義
    const accordionEl = ".accordion";
    //アコーディオンボタン定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテム定義
    const accordionItemEl = ".accordionItem";
    accordion(accordionEl, accordionButtonEl, accordionItemEl);
  })();

  (() => {
    /*
    ホバーメニュー用関数
    */
    hoverMenu();
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);
  })();

  (() => {
    /*
    モーダル用関数
    */
    modal();
  })();

  (() => {
    /*
    フォームバリデーション用関数
    */
    //フォームラッパークラス定義
    const formEl = ".p-form";
    //WPなどでフォームから出力されるエラークラスを定義
    const errorEl = "error";
    inputErrorCheck(formEl);
    inputAutofill();
  })();

  //   (() => {
  //     /*
  //     ページトップボタン用関数
  //     */
  //     //ボタンクラス定義
  //     const pageTopButtonEl = "#pageTop";
  //     //初期状態を定義
  //     const hide = true;
  //     pageTop(pageTopButtonEl, hide);
  //   })();

});
