export function scroll() {
  $(window).on("scroll", function () {
    const sliderHeight = $(".l-header").height();
    if (sliderHeight - 30 < $(this).scrollTop()) {
      $(".l-header").addClass("headerScroll");
      $(".l-header-pc__nav-contents-wrap").addClass("scroll-nav");
      $('.p-hamburger').addClass("p-hamburger__box-shadow");
      $('.l-header-pc__nav-list').addClass("l-header-pc__box-shadow");
    } else {
      $(".l-header").removeClass("headerScroll");
      $(".l-header-pc__nav-contents-wrap").removeClass("scroll-nav");
      $('.p-hamburger').removeClass("p-hamburger__box-shadow");
      $('.l-header-pc__nav-list').removeClass("l-header-pc__box-shadow");
    }

    if (30 < $(this).scrollTop()) {
      $(".p-model-modal__pc-button").addClass("scroll");
    } else {
      $(".p-model-modal__pc-button").removeClass("scroll");
    }
  });

  var btmMenu = $("#bottomMenu");
  btmMenu.hide();
  $(window).on("scroll", function () {
    if (window.matchMedia("(max-width: 991px)").matches) {
      if ($(this).scrollTop() > 60) {
        btmMenu.fadeIn();
      } else {
        btmMenu.fadeOut();
      }
    }
  });
}

export function smoothScroll() {
  $('a[href^="#"]').on("click", function () {
    var adjust = 0;
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
}

export function setFadeIn() {
  $(window).on("scroll", function () {
    $('.fadein').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 200) {
        $(function () {
          $('.fadein').each(function (i) {
            $(this).delay(i * 200).queue(function () {
              $(this).addClass('active');
            });
          });
        });
      }
    });
  });
}
