import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export function slider() {
  // topPage slider
  const swiper1 = new Swiper('.swiper-top', {
    direction: "horizontal",
    loop: true,
    simulateTouch: false,
    centeredSlides: false,
    effect: "fade",
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    pagination: {
      el: '.p-top-slider__pagination',
    },
  });

  // topPage plan thumbs
  const swiper2 = new Swiper('.p-top-plan__swiper-topPlan-thumbs', {
    loop: true,
    direction: "horizontal",
    spaceBetween: 20,
    slidesPerView: 3,
    simulateTouch: true,
  });

  // topPage plan main
  const swiper3 = new Swiper('.p-top-plan__swiper-topPlan', {
    loop: true,
    direction: "horizontal",
    simulateTouch: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: '.p-top-plan__navigation-next',
      prevEl: '.p-top-plan__navigation-prev',
    },

    pagination: {
      el: ".p-top-plan__pagination",
      type: "fraction",
    },

    thumbs: {
      swiper: swiper2
    }
  });

  // sample page thumbnail
  const swiper4 = new Swiper('.p-sample-detail__swiper-sample-thumbs', {
    loop: false,
    direction: "horizontal",
    spaceBetween: 14,
    slidesPerView: 4,
    simulateTouch: true,

    breakpoints: {
      768: {
        spaceBetween: 30,
      }
    }
  });

  // sample page main
  const swiper5 = new Swiper('.p-sample-detail__swiper-sample', {
    loop: true,
    direction: "horizontal",
    simulateTouch: true,

    navigation: {
      nextEl: '.p-sample-detail__navigation-next',
      prevEl: '.p-sample-detail__navigation-prev',
    },

    thumbs: {
      swiper: swiper4
    }
  });

  // plan page thumbnail
  const swiper6 = new Swiper('.p-plan-detail__swiper-plan-thumbs', {
    loop: false,
    direction: "horizontal",
    spaceBetween: 14,
    slidesPerView: 4,
    simulateTouch: true,

    breakpoints: {
      768: {
        spaceBetween: 30,
      }
    }
  });

  // plan page main
  const swiper7 = new Swiper('.p-plan-detail__swiper-plan', {
    loop: true,
    direction: "horizontal",
    simulateTouch: true,

    navigation: {
      nextEl: '.p-plan-detail__navigation-next',
      prevEl: '.p-plan-detail__navigation-prev',
    },

    thumbs: {
      swiper: swiper6
    }
  });

  // completion page thumbnail
  const swiper8 = new Swiper('.p-completion-detail__swiper-completion-thumbs', {
    loop: false,
    direction: "horizontal",
    spaceBetween: 14,
    slidesPerView: 4,
    simulateTouch: true,

    breakpoints: {
      768: {
        spaceBetween: 30,
      }
    }
  });

  // completion page main
  const swiper9 = new Swiper('.p-completion-detail__swiper-completion', {
    loop: true,
    direction: "horizontal",
    simulateTouch: true,

    navigation: {
      nextEl: '.p-completion-detail__navigation-next',
      prevEl: '.p-completion-detail__navigation-prev',
    },

    thumbs: {
      swiper: swiper8
    }
  });

  // topPage completion thumbs
  const swiper10 = new Swiper('.p-top-completion__swiper-top-completion-thumbs', {
    loop: true,
    direction: "horizontal",
    spaceBetween: 20,
    slidesPerView: 3,
    simulateTouch: true,
  });

  // topPage completion main
  const swiper11 = new Swiper('.p-top-completion__swiper-top-completion', {
    loop: true,
    direction: "horizontal",
    simulateTouch: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: '.p-top-completion__navigation-next',
      prevEl: '.p-top-completion__navigation-prev',
    },

    pagination: {
      el: ".p-top-completion__pagination",
      type: "fraction",
    },

    thumbs: {
      swiper: swiper10
    }
  });
}
